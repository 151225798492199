import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPostAction, createPostFileAction, updatePostAction } from "../../../../store/actions/PostActions";
import { useDispatch } from "react-redux";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();
  const [index, setIndex] = useState("");
  const [method, setMethod] = useState("option");
  const [title, setTitle] = useState("");
  const [editId, setEditId] = useState("");
  const [learningPathId, setLearningPathId] = useState();
  useImperativeHandle(ref, () => ({
    openModal(id) {
      setModalBox(true);
      setLearningPathId(id);
    },
    openEdit(item) {
      setModalBox(true);
      setIndex(item.index);
      setMethod(item.method);
      setTitle(item.sub_name);
      setEditId(item.id);
      setLearningPathId(item.learningPathId);
    },
  }));
  const submitActivity = async (e, id) => {
    e.preventDefault();
    const [method, index, title] = e.target;
    const data = {
      index: index.value,
      sub_name: title.value,
      learningPathId: learningPathId,
      practicumId: id,
      method: method.value,
    };
    let response = null;
    if (editId) {
      response = await dispatch(updatePostAction("/sub-module/update", data, editId));
    } else {
      response = await dispatch(createPostAction("/sub-module/store", data));
    }
    if (response) {
      props.fetchActivities(id);
      setModalBox(false);
      resetInput();
    }
  };
  const resetInput = () => {
    setTitle("");
    setIndex("");
    setMethod("option");
    setEditId("");
  };

  return (
    <>
      <Modal show={modalBox} onHide={setModalBox} className="fade bd-example-modal-lg" size="lg">
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create New Activity</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => {
                  setModalBox(false);
                  resetInput();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  submitActivity(e, props.id);
                }}
              >
                <div className="row">
                  <div className="form-group mb-3">
                    <label>Method</label>
                    <select
                      defaultValue={"option"}
                      id="inputState"
                      className="form-control"
                      value={method}
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      <option value="option" disabled>
                        Choose...
                      </option>
                      <option value="1">Virtual</option>
                      <option value="2">Hybrid</option>
                      <option value="3">On-Site</option>
                    </select>
                  </div>
                  <div className="form-group mb-3 row">
                    <div className="col-md-3">
                      <label htmlFor="method" className="form-label d-block">
                        Index
                      </label>
                      <input className="form-control" placeholder="Index" defaultValue={index ?? ""} name="index" />
                    </div>
                    <div className="col">
                      <label htmlFor="method" className="form-label d-block">
                        Title
                      </label>
                      <input className="form-control" placeholder="Title" defaultValue={title ?? ""} name="title" />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Save
                </button>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
});
export default BasicModal;
