import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";

const EventCalendar = ({data}) => {
   const [dataCalendar,setDataCalendar] = useState([]);
   const [dataEvent,setDataEvent] = useState([]);
   const calendarComponentRef = useRef();
   useEffect(()=>{
      data.forEach(element => {
         setDataCalendar((item)=>[...item,{
            title: element.schedule_name,
            start: new Date(element.startedAt),
            id: element.id
         }]);
         setDataEvent((item)=>[...item,{
            title: element.schedule_name,
            id: element.id
         }]);
      });
   },[data])
   
   const colorThemes = [
      { backgroundColor: "#f8f9fa", textColor: "#212529" },
      { backgroundColor: "#e8f5e9", textColor: "#1b5e20" },
      { backgroundColor: "#e3f2fd", textColor: "#0d47a1" },
      { backgroundColor: "#fff3e0", textColor: "#e65100" },
      { backgroundColor: "#ede7f6", textColor: "#4a148c" },
      { backgroundColor: "#fff9c4", textColor: "#f57f17" },
      { backgroundColor: "#fce4ec", textColor: "#880e4f" },
      { backgroundColor: "#e0f7fa", textColor: "#006064" },
      { backgroundColor: "#f1f8e9", textColor: "#33691e" },
      { backgroundColor: "#eceff1", textColor: "#263238" },
   ];

   /**
    * when we click on event we are displaying event details
    */
   const eventClick = (eventClick) => {
      Alert.fire({
         title: eventClick.event.title,
         html:
            `<div className="table-responsive">
      <table className="table">
      <tbody>
      <tr >
      <td>Title</td>
      <td><strong>` +
            eventClick.event.title +
            `</strong></td>
      </tr>
      <tr >
      <td>Start Time</td>
      <td><strong>
      ` +
            eventClick.event.start +
            `
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,

         showCancelButton: true,
         confirmButtonColor: "#d33",
         cancelButtonColor: "#3085d6",
         confirmButtonText: "Remove Event",
         cancelButtonText: "Close",
      }).then((result) => {
         if (result.value) {
            eventClick.event.remove(); // It will remove event from the calendar
            Alert.fire("Deleted!", "Your Event has been deleted.", "success");
         }
      });
   };

      return (
         <div className="animated fadeIn demo-app">
            <Row>
               <Col lg={3}>
                  <Card>
                     <div className="card-header border-0 pb-0">
                        <h4 className="fs-20 mb-0">Schedule</h4>
                     </div>
                     <Card.Body>
                        <div id="external-events">
                        <p>List of Timelines You Must Attend</p>

                           {dataEvent?.map((event, index) => (
                              <div
                                 className="external-event mt-0 ms-0 btn btn-block pe-none mb-2 d-flex gap-3" style={{ backgroundColor: colorThemes[index].backgroundColor, color: colorThemes[index].textColor }}
                                 title={event.title}
                                 data={event.id}
                                 key={event.id}
                              >
                                 <i class="fa-solid fa-circle-dot" style={{ color: colorThemes[index].textColor }}></i>
                                 {event.title}
                              </div>
                           ))}
                        </div>
                     </Card.Body>
                  </Card>
               </Col>

               <Col lg={9}>
                  <Card>
                     <Card.Body>
                        <div className="demo-app-calendar" id="mycalendartest">
                           <FullCalendar
                              defaultView="dayGridMonth"
                              header={{
                                 left: "prev,next today",
                                 center: "title",
                                 right:
                                    "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                              }}
                              rerenderDelay={10}
                              eventDurationEditable={false}
                              editable={true}
                              droppable={true}
                              plugins={[
                                 dayGridPlugin,
                                 timeGridPlugin,
                                 interactionPlugin,
                              ]}
                              ref={calendarComponentRef}
                              events={dataCalendar}
                              // drop={drop}
                              eventClick={eventClick}
                           // selectable={true}
                           />
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      );
}

export default EventCalendar;
