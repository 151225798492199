import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import CkEditorBlog from "../LearningPath/CkEditorBlog";
import { createPostAction, createPostFileAction, updatePostAction, updatePostFileAction } from "../../../store/actions/PostActions";
import { useDispatch } from "react-redux";
import CKEditorBlog from "../Utils/CkEditorBlog";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();
  const [index, setIndex] = useState('');
  const [type, setType] = useState('option');
  const [des, setDes] = useState('');
  const [editId, setEditId] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
    openEdit(item) {
      setModalBox(true);
      setIndex(item.index)
      setType(item.type)
      setDes(item.question)
      setEditId(item.id)
    }
  }));
  const submitWorkspace = async (e, id) => {
    const data = {
      question: selectedFile,
      index: index,
      type: type
    }
    if (props.refer === 'test') {
      data.testId = id;
    } else {
      data.subModuleId = id;
    }
    let response;
    if (editId) {
      response = await dispatch(updatePostAction('/question/update', data, editId))
    } else {
      response = await dispatch(createPostAction('/question/store', data))
    }
    if (response) {
      props.fetchActivities(id)
      setModalBox(false);
      resetInput();
    }
  }
  const submitActivity = async (e, id) => {
    e.preventDefault();
    const data = {
      index: index,
      question: des,
      type: type,
    }
    if (props.refer === 'test') {
      data.testId = id;
    } else {
      data.subModuleId = id;
      data.practicumId = props.practicumId
    }
    let response = null;
    if (editId) {
      response = await dispatch(updatePostAction('/question/update', data, editId))
    } else {
      response = await dispatch(createPostAction('/question/store', data))
    }
    if (response) {
      props.fetchActivities(id)
      setModalBox(false);
      resetInput();
    }
  }
  const resetInput = () => {
    setDes('<p></p>');
    setIndex('');
    setType('');
    setEditId('');
  }

  return (
    <>
      <Modal
        show={modalBox}
        onHide={setModalBox}
        className="fade bd-example-modal-lg"
        size="lg"
      >
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create New Activity</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => { setModalBox(false); resetInput() }}
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  type === '3' ? submitWorkspace(e, props.id) : submitActivity(e, props.id)
                }}
              >
                <div className="row">
                  <div className="form-group mb-3 col-12 row">
                    <div className="col-md-3">
                      <label>Index</label>
                      <input className="form-control" placeholder="Index" name="index" value={index} onChange={(e) => setIndex(e.target.value)} />
                    </div>
                    <div className="col">
                      <label>Type</label>
                      <select defaultValue={"option"} id="inputState" className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="option" disabled>
                          Choose...
                        </option>
                        <option value="1">Description</option>
                        <option value="2">Question</option>
                        <option value="3">Workspace</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-12">
                    {
                      type === '3' ?
                        <>
                          <label
                            htmlFor="file-upload"
                            style={{
                              cursor: "pointer",
                              display: "block",
                              textAlign: "center",
                              lineHeight: "250px",
                              border: "1px dashed #ccc",
                              borderRadius: "4px",
                            }}
                          >
                            Upload Image (450x250)
                          </label>
                          <input id="file-upload" type="file" style={{ display: "none" }} onChange={handleFileChange} />
                          {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                        </>
                        : <CKEditorBlog data={des} setData={setDes} />
                    }
                  </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Save
                </button>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
});
export default BasicModal;
