import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updatePostFileAction } from "../../../store/actions/PostActions";
import CKEditorBlog from "../Utils/CkEditorBlog";

const EditModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));

  const [index, setIndex] = useState('');
  const [des, setDes] = useState('<p></p>');
  const [title, setTitle] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (props.editData) {
      setIndex(props.editData.index);
      setDes(props.editData.des);
      setTitle(props.editData.name);
      setAbbreviation(props.editData.short_name);
    }
  }, [props.editData]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  // const [errors, setErrors] = useState(errorsObj);
  
  const submit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();

    if (selectedFile) {
      updatedFormData.append("icon", selectedFile);
      updatedFormData.append("oldIcon", props.editData.icon);
    }
    updatedFormData.append('name', title);
    updatedFormData.append('index', index);
    updatedFormData.append('des', des);
    updatedFormData.append('short_name', abbreviation);

    if (props.editData && props.editData.id) {
      // Dispatch edit action for the specific post
      dispatch(updatePostFileAction(`/learning-path/update`, updatedFormData, props.editData.id))
        .then(() => {
          setSelectedFile(null); // Clear file selection
          props.fetchData();
        })
        .catch((error) => {
          console.error("Error updating post:", error);
        });
    }
    setModalBox(false)
  };
  return (
    <>
      <Modal onHide={setModalBox} size="xl" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Learning Path</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalBox(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <div className="mb-3 d-block">
                  <label htmlFor="basic-url" className="form-label d-block">
                    Index
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Index"
                    value={index}
                    onChange={(e) => { setIndex(e.target.value) }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      textAlign: "center",
                      lineHeight: "250px",
                      border: "1px dashed #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    Upload Image (450x250)
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-3">
                  <label htmlFor="basic-url" className="form-label d-block">
                    Descriptions
                  </label>
                  <CKEditorBlog data={des} setData={setDes} />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => { setTitle(e.target.value) }}
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Abbreviation"
                    value={abbreviation}
                    onChange={(e) => { setAbbreviation(e.target.value) }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={(e) => setModalBox(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default EditModal;
