import React from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "react-table";

const StudentListModal = ({ show, handleClose, group, role, action }) => {
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{group.group_name} information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex gap-3">
          {/* <span className="btn btn-secondary">{group}</span> */}
        </div>
        <Table striped bordered hover >
          <thead>

            <tr className="text-dark">
              <th className="text-dark">#</th>
              <th className="text-dark">Name</th>
              <th className="text-dark">Username</th>
              {
                role === 1 && action ?
                <>
                  <th className="text-dark">Email</th>
                  <th className="text-dark">Prodi</th>
                  <th className="text-dark">Universitas</th>
                </>:
                role === 1 && !action &&
                <>
                  <th className="text-dark">Action</th>
                </>
              }
            </tr>
          </thead>
          <tbody>
            {group.group_students?.map((student, index) => (
              <tr key={index} className={`${role === 1 && !action && "btn btn-transparent d-table-row fw-normal"} text-start`} onClick={() => {
                if (role === 1 && !action) {
                  navigate('/admin/student-activity/' + student.user.id + '/' + group.scheduleId);
                }
              }}>
                <td>{index + 1}</td>
                <td>{student.user.first_name}</td>
                <td>{student.user.username}</td>
                {
                  role === 1 && action ? <>
                    <td>{student.user.email}</td>
                    <td>{student.user.prodi}</td>
                    <td>{student.user.universitas}</td>
                  </>:
                  role === 1 && !action && <>
                  <td>Details</td>
                </>
                }
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StudentListModal;
