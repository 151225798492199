import React, { useState, useContext, useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import ApexCharts from 'apexcharts';
import DatePicker from "react-datepicker";
import { Dropdown } from 'react-bootstrap';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//import DonutChart from './Dashboard/DonutChart';
//import DonutChart2 from './Dashboard/DonutChart2';

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import HomeSlider from './Dashboard/HomeSlider';
import ReviewsHomeSlider from './Dashboard/ReviewsHomeSlider';
import DropDownBlog from './DropDownBlog';
import { useDispatch } from 'react-redux';
import { getPostsAction } from '../../../store/actions/PostActions';
import ScheduleCard from '../Schedule/ScheduleCard';

//images
//import small1 from './../../../images/profile/small/pic1.jpg';

const ProjectAreaChart = loadable(() =>
	pMinDelay(import("./Banking/ProjectAreaChart"), 1000)
);
const EmailPieChart = loadable(() =>
	pMinDelay(import("./Dashboard/EmailPieChart"), 1000)
);
const StatisticBarChart = loadable(() =>
	pMinDelay(import("./Dashboard/StatisticBarChart"), 1000)
);
const RedialChart = loadable(() =>
	pMinDelay(import("./Dashboard/RedialChart"), 1000)
);


const CharacterData = [
	{ svgColor: '#FFD125', changeClass: 'up', title: 'Income' },
	{ svgColor: '#FCFCFC', title: 'Expense' },
];

const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);

	const [startDate, setStartDate] = useState(new Date());
	const [selectBtn, setSelectBtn] = useState("This Month");
	const [selectBtn2, setSelectBtn2] = useState("This Month");

	const childRef = useRef();

	const dispatch = useDispatch();
	const [pageData, setPageData] = useState([]);
	const handleSeries = (value) => {
		//alert('dd');
		ApexCharts.exec('assetDistribution', 'toggleSeries', value)
	}
	// const handleSeries2 = () => {
	//   //alert('dd');
	//   ApexCharts.exec('assetDistribution', 'toggleSeries', 'Expense')
	// }

	const projectSeries = (value) => {
		//alert('dd');
		ApexCharts.exec('assetDistribution2', 'toggleSeries', value)
	}
	const fetchData = async () => {
		try {
			const response = await dispatch(
				getPostsAction("/schedule/index", {
					params: {
						pageSize: 8,
						pageNumber: 1,
					},
				})
			);
			if (response) {
				setPageData(response);
			}
		} catch (error) {
			console.error("Error fetching posts:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="page-titles style1">
								<div className="d-flex align-items-center">
									<h2 className="heading">Dashboard</h2>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12">
							<div className="card p-4 d-flex flex-row gap-5">
								{pageData.map((item, index) => (
									<ScheduleCard data={item} key={index} action={null} role={1}/>
								))}
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}
export default Home;