import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-modal-video/scss/modal-video.scss";
import course7 from "./../../../../images/course/7.jpg";
import CkEditorBlog from "../../Utils/CkEditorBlog";
import { Link, useLocation } from "react-router-dom";
import Meet from "./Meet";
import { socket } from "../../../../services/SocketService";
import { useDispatch } from "react-redux";
import { createPostAction, getPostsAction } from "../../../../store/actions/PostActions";
import Chat from "./Chat";
import "../../../../css/check.css";
import { ThreeDot } from "react-loading-indicators";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const practicum = {
  id: "1",
  image: course7,
  tags: ["Physics", "Bernoulli"],
  title: "Drone",
};

const progress = {
  finished: 3,
  total: 18,
  current: "Workspace",
};
function RemotePeerVideo({ peer, remoteIframe }) {
  const canvasRef = useRef(null);
  const cropAndDisplay = () => {
    const video = peer.videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const draw = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      context.drawImage(video, remoteIframe.x, remoteIframe.y, remoteIframe.width, remoteIframe.height, 0, 0, canvas.width, canvas.height);
      requestAnimationFrame(draw);
    };
    draw();

  };
  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (peer.stream) {
        const videoTracks = peer.stream.getVideoTracks();
        const isCameraOn = videoTracks.some((track) => track.enabled);

        if (!isCameraOn) {
          console.log(`Peer ${peer.id} has the camera off.`);
        } else {
          console.log(`Peer ${peer.id} has the camera On.`);
        }
      }
    };
    if (peer.videoRef.current && peer.stream) {
      peer.videoRef.current.srcObject = peer.stream;

      peer.videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
      if (peer.stream.active) {
        handleLoadedMetadata();
      }
    }

    console.log(peer)
    return () => {
      if (peer.videoRef.current) {
        peer.videoRef.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, [peer.videoRef, peer.stream]);

  return (
    <>
      
      <video
        id={peer.id}
        ref={peer.videoRef}
        playsInline
        autoPlay
        className="border-top border-2 border-dark w-100"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </>

  );
}
function PracticumStart() {
  const user = JSON.parse(localStorage.getItem('userDetails'));
  const [timer, setTimer] = useState(1800);
  let randomColors = [
    "#FF5733", "#33FF57", "#5733FF", "#33FFF5", "#FF33F5",
    "#F5FF33", "#FF3380", "#33FF80", "#8033FF", "#33FFB8",
    "#FFB833", "#F533FF", "#33A1FF", "#A1FF33", "#FF33A1",
    "#33FF33", "#FF3333", "#33A133", "#A133FF", "#F5A133"
  ];

  const location = useLocation();
  const { moduleId, scheduleId } = location.state || {};
  const methodList = ['', 'Virtual', 'Hybrid', 'On-site'];
  const [active, setActive] = useState(0);
  const iframeRef = useRef(null);
  const [remoteIframe,setRemoteIframe] = useState([]);
  const [coords, setCoords] = useState([]);
  const [position, setPosition] = useState([]);
  const isClickedRef = useRef(false);
  const [shareScreen, setShareScreen] = useState(false);
  const [remoteShare, setRemoteShare] = useState({});
  const [dataIds, setDataIds] = useState([]);
  const [activeLearningPath, setActiveLearningPath] = useState(0);
  const [divSize, setDivSize] = useState({ width: 1, height: 1, left: 1, top: 1 });
  const [answer, setAnswer] = useState("<p></p>");
  const [loading, setLoading] = useState(0);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [tick, setTick] = useState(0);
  // useEffect(() => {
  //   if (MouseRef.current) {
  //     const rect = MouseRef.current.getBoundingClientRect();
  //     setDivSize({ width: rect.width, height: rect.height, left: rect.left, top: rect.top })
  //   }
  // }, [MouseRef.current])
  // const handleMouseMove = (event) => {
  //   if (MouseRef.current) {
  //     const rect = MouseRef.current.getBoundingClientRect();
  //     const x = event.clientX / rect.width;
  //     const y = event.clientY / rect.height;
  //     if (isClickedRef.current) {
  //       setPosition({ x, y, clicked: { x, y } });
  //     } else {
  //       setPosition({ x, y, clicked: {} });
  //     }
  //   }
  // };
  // useEffect(() => {
  //   console.log(coords)
  // }, [coords])
  // const handleMouseDown = () => {
  //   isClickedRef.current = true;
  // };

  // const handleMouseUp = () => {
  //   isClickedRef.current = false;
  // };
  // const handleIframeLoad = () => {
  //   if (MouseRef.current && MouseRef.current.contentWindow) {
  //     MouseRef.current.contentWindow.document.addEventListener('mousedown', handleMouseDown);
  //     MouseRef.current.contentWindow.document.addEventListener('mousemove', handleMouseMove);
  //     MouseRef.current.contentWindow.document.addEventListener('mouseup', handleMouseUp);
  //   }
  // }
  // useEffect(() => {
  //   return () => {
  //     if (MouseRef.current && MouseRef.current.contentWindow) {
  //       MouseRef.current.contentWindow.document.removeEventListener('mousedown', handleMouseDown);
  //       MouseRef.current.contentWindow.document.removeEventListener('mousemove', handleMouseMove);
  //       MouseRef.current.contentWindow.document.removeEventListener('mouseup', handleMouseUp);
  //     }
  //   };
  // }, [MouseRef.current]);
  // useEffect(() => {
  //   const clickAtCoordinates = ({ x, y }) => {
  //     const iframe = MouseRef.current;
  //     if (iframe && iframe.contentWindow && iframe.contentDocument) {
  //       const iframeDoc = iframe.contentDocument;
  //       const iframeWindow = iframe.contentWindow;
  //       const iframeRect = iframe.getBoundingClientRect();

  //       // Calculate the coordinates relative to the iframe's viewport
  //       const iframeX = x * iframeRect.width;
  //       const iframeY = y * iframeRect.height;

  //       // Get the element at the specified coordinates inside the iframe
  //       const el = iframeDoc.elementFromPoint(iframeX, iframeY);

  //       if (el) {
  //         // Create and dispatch a click event inside the iframe
  //         const clickEvent = new iframeWindow.MouseEvent('click', {
  //           bubbles: true,
  //           cancelable: true,
  //           view: iframeWindow,
  //           clientX: iframeX,
  //           clientY: iframeY,
  //         });
  //         el.dispatchEvent(clickEvent);
  //       } else {
  //         console.log('No element found at the specified coordinates inside iframe.');
  //       }
  //     } else {
  //       console.log('Iframe is not accessible.');
  //     }
  //   };

  //   if (Object.keys(coords.clicked).length !== 0) {
  //     clickAtCoordinates(coords.clicked);
  //   }
  // }, [coords.clicked]);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        }
        return prevTimer;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const dispatch = useDispatch();
  const [chatOpen, setChatOpen] = useState(false);
  const [learningMaterials, setLearningMaterials] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [subModuleIds, setSubModuleIds] = useState([]);
  const [virtual, setVirtual] = useState([]);
  const openChat = () => {
    setChatOpen(!chatOpen);
  }
  const fetchActivity = async (subId) => {
    try {
      const response = await dispatch(getPostsAction('/activity/index', {
        params: {
          userId: user.localId,
          scheduleId: scheduleId
        },
      }));
      if (response) {
        console.log(response)
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
  const fetchTimer = async (subId) => {
    try {
      const response = await dispatch(getPostsAction('/schedule/timer/' + scheduleId));
      if (response) {
        setTimer(response.time)
        console.log(response)
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
  const fetchQuestions = async (subId) => {
    setLoadingQuestion(true);
    try {
      const response = await dispatch(getPostsAction('/question/index', {
        params: {
          subModuleId: subId,
          practicumId: moduleId
        },
      }));
      if (response) {
        setQuestions(response)
        setLoadingQuestion(false);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }

  const fetchData = async () => {
    try {
      const response = await dispatch(
        getPostsAction('/group/get-group', {
          params: {
            scheduleId: scheduleId,
          },
        })
      );
      if (response) {
        const response2 = await dispatch(
          getPostsAction('/learning-path/step')
        );
        setVirtual(response.virtual);
        if (response2) {
          let data = [];
          setLearningMaterials(response2);
          setActiveLearningPath(response2[0].name);
          setActive(response2[0].sub_modules[0]?.id);
          response2.forEach(item => {
            data[item.name] = item.sub_modules.map((sub) => sub.id)
          });
          setDataIds(data);
          setSubModuleIds(
            response2.flatMap((item) => item.sub_modules.map((sub) => sub.id))
          );
        }
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  useEffect(() => {
    socket.emit('join_room', { username: user.username, room: scheduleId });
    socket.on("iframe",(iframeRef)=>{
      setRemoteIframe(iframeRef)
  });
    fetchData();
    fetchTimer();
    fetchActivity();
  }, []);
  useEffect(() => {
    fetchQuestions(active)
  }, [active])

  const handleNext = async (e) => {
    if (dataIds[activeLearningPath].indexOf(active) < dataIds[activeLearningPath].length - 1) {
      setActive(dataIds[activeLearningPath][dataIds[activeLearningPath].indexOf(active) + 1])
    } else if (dataIds.indexOf(activeLearningPath) < Object.keys(dataIds).length - 1) {
      const index = Object.keys(dataIds);
      const nextKey = index[index.indexOf(activeLearningPath) + 1];
      const currentKey = index[index.indexOf(activeLearningPath)];

      const time = {
        [currentKey]: localStorage.getItem('detected') ?? 0,
        finished: timer
      }
      setActiveLearningPath(nextKey)
      setActive(dataIds[nextKey][0])
      const data = {
        'scheduleId': scheduleId,
        'time': time
      }
      const response = dispatch(createPostAction("/activity/store", data));
      localStorage.setItem('detected', 0);
    } else {
      window.location.href = `/student/practicum`;
      return null;
    }


  };
  const handlePrevious = async () => {
    if (dataIds[activeLearningPath].indexOf(active) > 0) {
      setActive(dataIds[activeLearningPath][dataIds[activeLearningPath].indexOf(active) - 1])
    }
  };

  const submit = async (e, questionId) => {
    e.preventDefault();
    setLoading(questionId);
    const data = {
      'questionId': questionId,
      'subModuleId': dataIds[activeLearningPath][dataIds[activeLearningPath].indexOf(active)],
      'answer': answer[questionId]
    }
    const response = await dispatch(createPostAction("/answer/store", data));
    if (response) {
      setQuestions(prevData =>
        prevData.map(question => {
          if (question.id === questionId) {
            return {
              ...question,
              answer: response.data
            };
          }
          return question;
        })
      );
      setLoading(0);
      setTick(questionId);
      setTimeout(() => {
        setTick(0);
      }, 2000);
    }
  };
  useEffect(() => {
    console.log(questions)
  }, [questions])
  const handleAnswer = (answerData, questionId) => {
    setAnswer((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answerData,
    }));
  };

  return (
    <>
      <div className="row ">
        <div className="col-xl-3 col-xxl-3">
          <div className="card h-auto">
            <div className="card-body">
              <div>
                <div className="card-body pt-0 bg-white" >
                  <div className="progress-box">
                    <ProgressBar now={(progress.finished / progress.total) * 100} />
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-0 fs-14 font-w600">{progress.current}</h5>
                      <span className="font-w600">
                        {progress.finished}/{progress.total}
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="m-0 w-100" />
                <PerfectScrollbar style={{ height: "78vh" }}>
                  {
                    learningMaterials.length == 0 ?
                      <div className="d-flex flex-column gap-3">
                        <Skeleton style={{ height: '5rem', borderRadius: '10px' }} />
                        <Skeleton style={{ height: '5rem', borderRadius: '10px' }} />
                        <Skeleton style={{ height: '5rem', borderRadius: '10px' }} />
                      </div>
                      :
                      learningMaterials.map((item, key) => (
                        <div key={key} className="about-content">
                          <h4 className="heading mb-3">{item.name}</h4>
                          <div
                            style={{ height: "auto" }}
                            id="DZ_W_TimeLine"
                            className="widget-timeline dlab-scroll height370 ps ps--active-y"
                          >
                            <ul className="timeline">
                              {item.sub_modules.map(({ id, sub_name, method, status = 0 }, index) => {
                                const badge = id === active ? "timeline-badge primary" : "timeline-badge dark";
                                return (
                                  <li key={'subModules' + id} className="cursor-pointer" onClick={() => { setActive(id) }}>
                                    <div className={badge}></div>
                                    <div className="timeline-panel text-muted">
                                      <span>{virtual.includes(item.id) ? 'Virtual' : 'On-site'}</span>
                                      <h6 className="mb-0">{sub_name}</h6>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ))}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-xxl-7">
          <div className="card h-auto">
            <div className="card-body">
              <PerfectScrollbar style={{ height: "79vh" }}>
                {
                  active == 40 ?
                    <div 
                      className="h-100 w-100 d-flex flex-column justify-content-center"
                    >
                      {/* {
                        Object.entries(coords).map(([student, item], index) => (
                          <span className="position-absolute d-flex flex-column fw-bold fs-4" key={'coord' + student} style={{ left: `${(item.x * divSize.width) + 25}px`, top: `${(item.y * divSize.height) + 25}px` }}>
                            <span className="p2 px-3 rounded text-white" style={{ backgroundColor: randomColors[index] }}>{student}</span>
                            <i className={`fa-solid fa-arrow-pointer`} style={{ color: randomColors[index] }}></i>
                          </span>
                        ))
                      } */}
                      {
                        remoteShare.id ?
                          <RemotePeerVideo key={remoteShare.id} peer={remoteShare} remoteIframe={remoteIframe} /> :
                          <>
                            <iframe
                              id="iframe"
                              ref={iframeRef}
                              className="w-100 h-100"
                              allow="displaycapture"
                              src={'https://hiprolab.com/bridge.html'}
                            />
                            <div className="text-center mt-3">
                              {
                                !shareScreen &&
                                <button className="btn btn-transparent" onClick={() => { setShareScreen(true) }}>
                                  Share Screen
                                  <i class="fa-solid fa-tv ms-3"></i>
                                </button>
                              }
                            </div>
                          </>
                      }

                    </div>
                    :
                    !loadingQuestion ?
                      questions.map((item, index) => {
                        return (
                          <div key={'question' + index}>
                            <div className="course-content d-flex justify-content-end flex-wrap">
                              <span className="text-muted">{item.type == 1 ? 'Description' : 'Question'}</span>
                            </div>
                            <div className="course-details-tab style-2 mt-4">
                              <div className="about-content" dangerouslySetInnerHTML={{ __html: item.question }} />
                            </div>
                            {item.type === 2 && (
                              <>
                                <div className="style-2 mt-4">
                                  <h6>Insert your answer below:</h6>
                                </div>
                                <div className="form-group mb-4 col-12">
                                  <CkEditorBlog data={item.answer?.answer} setData={handleAnswer} id={item.id} />
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex">
                                    <button className="btn btn-sm btn-primary mb-4 my-auto align-items-center" style={{ width: '150px' }} disabled={answer[item.id] === '' || loading == item.id || (item.answer.answer === answer[item.id]) ? true : false} onClick={(e) => submit(e, item.id)}>
                                      {loading == item.id ?
                                        <ThreeDot color="#80c8fc" size="small" text="" textColor="" />
                                        :
                                        'Save this answer'
                                      }

                                    </button>
                                    {tick === item.id &&
                                      <div className="wrapper"> <svg className="animated-check" viewBox="0 0 24 24">
                                        <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                                      </div>
                                    }
                                  </div>

                                  <div className="d-flex gap-3">
                                    <h6>Status : </h6>
                                    {
                                      item.answer.answer ?
                                        <strong className="text-success">Answered</strong> :
                                        <strong className="text-danger">No answer yet</strong>
                                    }
                                  </div>
                                </div>

                              </>
                            )}
                          </div>)
                      }) :
                      <>
                        <Skeleton style={{ height: '25rem' }} />
                        <Skeleton count={10} style={{ marginTop: '15px' }} />
                        <Skeleton style={{ width: '90%', marginTop: '15px' }} />
                      </>
                }
              </PerfectScrollbar>
            </div>
          </div>
          <div className="d-flex justify-content-between gap-2">

            {
              Object.keys(dataIds).length > 0 &&
              <>
                <button className="btn btn-warning btn-block" onClick={() => handlePrevious()} disabled={dataIds[activeLearningPath].indexOf(active) <= 0}>
                  Previous
                </button>
                <button className="btn btn-primary btn-block" onClick={() => handleNext()}>
                  {((dataIds[activeLearningPath].indexOf(active) >= dataIds[activeLearningPath].length - 1) && dataIds.indexOf(activeLearningPath) >= Object.keys(dataIds).length - 1) ? 'Submit' : 'Next'}
                </button>
              </>
            }
          </div>
        </div>
        <div className={`h-100 position-fixed d-flex ${chatOpen ? 'col-xl-5 col-xxl-5 justify-content-end ' : 'col-xl-2 col-xxl-3 col-md-3'} `} style={{ right: '0px' }}>
          <Chat socket={socket} room={scheduleId} user={user} chatOpen={chatOpen} />
          <div className={chatOpen ? 'col-xl-5 col-xxl-3 col-md-3' : 'w-100'}>
            <div className="bg-white h-auto rounded-0 rounded-top rounded-4">
              <div className="text-center border-0 justify-content-center">
                <h3 className="m-0">
                  {Math.floor(timer / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
                    ":" +
                    (timer % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}
                </h3>
              </div>
            </div>
            <div className="bg-dark" style={{ height: '88%' }}>
              <Meet socket={socket} user={user} setCoords={setCoords} position={position} openChat={openChat} shareScreen={shareScreen} iframeRef={iframeRef} setRemoteShare={setRemoteShare} />
            </div>
            <a
              href={`/student/dashboard`}
              className="btn btn-danger btn-block mb-4 rounded-0 rounded-bottom rounded-4"
            >
              Exit
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default PracticumStart;
