import React from 'react';

const CkEditorBlog2 = ({ data, config, setData, onSubmit }) => {
    return (
        <>
            {/* <CKEditor
                editor={ClassicEditor}
                data={data}
                {...(config ? { config } : {})}
                onReady={editor => {
                    editor.editing.view.document.on('keydown', (event, data) => {
                        if (data.keyCode === 13 && config) {
                            data.preventDefault();
                            event.stop();
                            onSubmit(editor.getData());
                        }
                    });
                }}
                
                onChange={(event, editor) => {
                    const text = editor.getData();
                    setData(text)
                    //console.log( { event, editor, data } );
                }}
                onBlur={(event, editor) => {
                    //console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                    //console.log( 'Focus.', editor );
                }} 
            />*/}
        </>
    );
};

export default CkEditorBlog2;