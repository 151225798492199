import React, { useCallback, useEffect, useRef, useState } from "react";
import Peer from "peerjs";
import { Modal } from "react-bootstrap";
import config from "../../../../config";
import * as faceapi from 'face-api.js';
import { runDetector } from "./utils/Detector";
function RemotePeerVideo({ peer }) {
    useEffect(() => {
        const handleLoadedMetadata = () => {
            if (peer.stream) {
                const videoTracks = peer.stream.getVideoTracks();
                const isCameraOn = videoTracks.some((track) => track.enabled);

                if (!isCameraOn) {
                    console.log(`Peer ${peer.id} has the camera off.`);
                } else {
                    console.log(`Peer ${peer.id} has the camera On.`);
                }
            }
        };
        if (peer.videoRef.current && peer.stream) {
            peer.videoRef.current.srcObject = peer.stream;

            peer.videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);

            if (peer.stream.active) {
                handleLoadedMetadata();
            }
        }

        return () => {
            if (peer.videoRef.current) {
                peer.videoRef.current.removeEventListener(
                    "loadedmetadata",
                    handleLoadedMetadata
                );
            }
        };
    }, [peer.videoRef, peer.stream]);

    return (
        <div className="d-flex" style={{ position: "relative", height: "173px" }} key={peer.id}>
            <video
                id={peer.id}
                ref={peer.videoRef}
                playsInline
                autoPlay
                className="border-top border-2 border-dark w-100"
                height={173}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <div
                className="text-center"
                style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                    zIndex: 2,
                }}
            >
                <span className="bg-dark px-3 rounded-pill text-white opacity-75 w-auto">
                    {peer.id}
                </span>
            </div>
        </div>
    );
}

export default function Meet({ socket, user, setCoords, position, room, openChat, camera = false, shareScreen, setRemoteShare, iframeRef }) {
    const webcamRef = useRef(null);
    const [peer, setPeer] = useState(null);
    const [video, setVideo] = useState(false);
    const [audio, setAudio] = useState(true);
    const [hide, setHide] = useState(false);
    const [localStream, setLocalStream] = useState(null);
    const [remotePeers, setRemotePeers] = useState({});
    const [modalCam, setModalCam] = useState(camera ? false : true);
    const [localShare, setLocalShare] = useState(null);
    const callsRef = useRef({});
    const canvasRef = useRef();
    const videoWidth = 640;
    const videoHeight = 480;
    let startTime = null;
    let conditionStartTime = null;
    let totalConditionTime = 0;
    const [modelsLoaded, setModelsLoaded] = useState(false);

    useEffect(() => {
        const loadModels = async () => {
            const MODEL_URL = config.url + '/models';

            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
            ]).then(setModelsLoaded(true));
        }
        loadModels();
    }, []);
    const constraints = {
        video: {
            width: { ideal: videoWidth },
            height: { ideal: videoHeight },
            frameRate: { ideal: 15 }
        },
        audio: true
    };

    useEffect(() => {
        toggleVideo();
    }, []);
    useEffect(() => {
        if (iframeRef.current) {
            console.log(iframeRef)
        }
        console.log(iframeRef, 'w')
    }, [iframeRef]);
    const setWebcamRef = useCallback(
        (videoElement) => {
            if (videoElement) {
                videoElement.srcObject = localStream;
            }
            webcamRef.current = videoElement;
        },
        [localStream]
    );
    const connectPeer = (username, streamPeer) => {
        const newPeer = new Peer(username, {
            path: "/peerjs",
            port: "443",
            host: "api.hiprolab.com",
            secure: true,
            config: {
                iceServers: [
                    { urls: 'stun:stun01.sipphone.com' },
                    { urls: 'stun:stun.ekiga.net' },
                    { urls: 'stun:stun.fwdnet.net' },
                    { urls: 'stun:stun.ideasip.com' },
                    { urls: 'stun:stun.iptel.org' },
                    { urls: 'stun:stun.rixtelecom.se' },
                    { urls: 'stun:stun.schlund.de' },
                    { urls: 'stun:stun.l.google.com:19302' },
                    { urls: 'stun:stun1.l.google.com:19302' },
                    { urls: 'stun:stun2.l.google.com:19302' },
                    { urls: 'stun:stun3.l.google.com:19302' },
                    { urls: 'stun:stun4.l.google.com:19302' },
                    { urls: 'stun:stunserver.org' },
                    { urls: 'stun:stun.softjoys.com' },
                    { urls: 'stun:stun.voiparound.com' },
                    { urls: 'stun:stun.voipbuster.com' },
                    { urls: 'stun:stun.voipstunt.com' },
                    { urls: 'stun:stun.voxgratia.org' },
                    { urls: 'stun:stun.xten.com' },
                    {
                        urls: "stun:103.132.124.200:5350",
                    },
                    {
                        urls: "turn:103.132.124.200:3479",
                        username: "hiprolab",
                        credential: "testHiprolab2024",
                    },
                ]
            },
        });


        newPeer.on("open", (id) => {
            socket.emit("peer-users", id);
            if (username == 'sharescreen') {
                socket.emit("iframe", iframeRef.current.getBoundingClientRect());
            }
        });

        newPeer.on("call", (call) => {
            if (call.peer !== username) {
                call.answer(streamPeer);

                if (call.peer == 'sharescreen') {
                    call.on("stream", (incomingStream) => {
                        const videoRef = React.createRef();
                        setRemoteShare({
                            id: call.peer, videoRef, stream: incomingStream
                        });

                    });
                } else {
                    callsRef.current[call.peer] = call;
                    call.on("stream", (incomingStream) => {
                        setRemotePeers((prevRemotePeers) => {
                            if (call.peer in prevRemotePeers) {
                                return {
                                    ...prevRemotePeers,
                                    [call.peer]: {
                                        ...prevRemotePeers[call.peer],
                                        stream: incomingStream,
                                    },
                                };
                            } else {
                                const videoRef = React.createRef();
                                return {
                                    ...prevRemotePeers,
                                    [call.peer]: { id: call.peer, videoRef, stream: incomingStream },
                                };
                            }
                        });
                    });
                }

                call.on("close", () => {
                    if (call.peer === 'sharescreen') {
                        setRemoteShare({});
                    } else {
                        setRemotePeers((prevRemotePeers) => {
                            const updatedRemotePeers = { ...prevRemotePeers };
                            delete updatedRemotePeers[call.peer];
                            return updatedRemotePeers;
                        });
                        delete callsRef.current[call.peer];
                    }
                });
            }
        });

        setPeer(newPeer);

        return () => {
            newPeer.disconnect();
            newPeer.destroy();
        };
    }
    useEffect(() => {
        if (!modalCam && localStream) {
            connectPeer(user.username, localStream);
        }
    }, [modalCam]);

    useEffect(() => {
        if (!modalCam && peer && localStream) {
            socket.on("existing-peers", (existingPeers) => {
                existingPeers.forEach((peerId) => {
                    if (peerId !== user.username) {
                        connectToNewPeer(peerId);
                    }
                });
            });

            socket.on("new-peer", (peerId) => {
                if (peerId !== user.username) {
                    connectToNewPeer(peerId);
                }
            });

            socket.on("peer-disconnected", (peerId) => {
                if (callsRef.current[peerId]) {
                    callsRef.current[peerId].close();
                    delete callsRef.current[peerId];
                }
                setRemotePeers((prevRemotePeers) => {
                    const updatedRemotePeers = { ...prevRemotePeers };
                    delete updatedRemotePeers[peerId];
                    return updatedRemotePeers;
                });
            });

            return () => {
                socket.off("existing-peers");
                socket.off("new-peer");
                socket.off("peer-disconnected");
            };
        }
    }, [peer, modalCam, localStream]);


    // function deteksiMataTidakMenghadap(mataKiri, toleransi, state) {
    //     return mataKiri.some(posisi => {
    //         const jarakX = Math.abs(posisi._x);
    //         const jarakY = Math.abs(posisi._y);
    //         if (state) {
    //             return jarakX < toleransi.x || jarakY < toleransi.y;

    //         } else {
    //             return jarakX > toleransi.x || jarakY < toleransi.y;
    //         }
    //     });
    // }
    const handleVideoOnPlay = async (videoNode) => {
        const video = videoNode.target;
        if (video.readyState < 3) return;
        if (startTime === null) {
            startTime = performance.now();
        }
        await runDetector(video, (data) => {
            const currentTime = performance.now();
            if ((data.turn >= 158) || (data.turn <= 37) || (data.yaw <= 4) || ((data.yaw >= 178) && (data.turn >= 84))) {
                if (conditionStartTime === null) {
                    conditionStartTime = currentTime;
                }
            } else {
                if (conditionStartTime !== null) {
                    totalConditionTime += (currentTime - conditionStartTime) / 1000;
                    conditionStartTime = null;
                    localStorage.setItem('detected', Math.round(totalConditionTime))
                }
            }

        });
    }
    const toggleAudio = () => {
        const newAudioState = !audio;
        setAudio(newAudioState);

        if (localStream) {
            localStream.getAudioTracks().forEach((track) => {
                track.enabled = newAudioState;
            });
        }
    };

    const toggleShareScreen = async () => {
        if (!localShare) {
            try {
                const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
                const videoTrack = stream.getVideoTracks()[0];
                
                // Create a video element to display the stream
                const video = document.createElement('video');
                video.srcObject = new MediaStream([videoTrack]);
                video.play();
    
                // Wait for the video to load metadata
                await new Promise((resolve) => (video.onloadedmetadata = resolve));
    
                // Create a canvas to crop the video
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
    
                // Set the desired crop area
                const cropWidth = 1300;  // Desired width of cropped area
                const cropHeight = 1300; // Desired height of cropped area
                canvas.width = cropWidth;
                canvas.height = cropHeight;
    
                // Get the actual video dimensions
                const videoWidth = video.videoWidth;
                const videoHeight = video.videoHeight;
    
                // Define the crop area from the video
                const cropX = (videoWidth - cropWidth) / 2;  // Center horizontally
                const cropY = (videoHeight - cropHeight) / 2; // Center vertically
    
                // Draw the cropped area from the video onto the canvas
                const cropInterval = setInterval(() => {
                    ctx.drawImage(
                        video,                // Source video
                        cropX + 120,                // X coordinate to start cropping
                        cropY,                // Y coordinate to start cropping
                        cropWidth,            // Width of the crop area
                        cropHeight,           // Height of the crop area
                        0,                    // Destination canvas X coordinate
                        0,                    // Destination canvas Y coordinate
                        cropWidth,            // Destination width on canvas
                        cropHeight            // Destination height on canvas
                    );
                }, 1000 / 60); // 30 FPS
    
                // Create a new video stream from the canvas
                const croppedStream = canvas.captureStream(30); // 30 FPS
                connectPeer('sharescreen', croppedStream);
    
                // Stop cropping when sharing ends
                videoTrack.addEventListener('ended', () => {
                    clearInterval(cropInterval);
                    croppedStream.getTracks().forEach((track) => track.stop());
                    setLocalShare(null);
                    replaceTracksInPeers(localStream);
                });
    
                setLocalShare(croppedStream);
            } catch (error) {
                console.error("Error accessing display media.", error);
            }
        } else {
            localShare.getTracks().forEach((track) => track.stop());
            setLocalShare(null);
            replaceTracksInPeers(localStream);
        }
    };
    const toggleVideo = async () => {
        const newVideoState = !video;
        setVideo(newVideoState);

        try {
            if (!localStream) {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                setLocalStream(stream);
                if (webcamRef.current) {
                    webcamRef.current.srcObject = stream;
                }
            } else {
                localStream.getVideoTracks().forEach((track) => {
                    track.enabled = newVideoState;
                });
            }
        } catch (error) {
            console.error("Error accessing media devices.", error);
        }
    };

    useEffect(() => {
        if (shareScreen) {
            toggleShareScreen()
        }
    }, [shareScreen])
    const replaceTracksInPeers = (stream) => {
        Object.values(callsRef.current).forEach((call) => {
            const sender = call.peerConnection.getSenders().find((s) => s.track.kind === "video");
            if (sender) {
                sender.replaceTrack(stream.getVideoTracks()[0]);
            }
        });
    };

    const connectToNewPeer = (peerId) => {
        if (!localStream) {
            console.warn("Local stream not available yet.");
            return;
        }

        const call = peer.call(peerId, localStream);
        call.on("stream", (incomingStream) => {
            const videoRef = React.createRef();

            if (peerId == 'sharescreen') {
                setRemoteShare({ id: peerId, videoRef, stream: incomingStream })

            } else {
                callsRef.current[peerId] = call;
                setRemotePeers((prevRemotePeers) => {
                    return {
                        ...prevRemotePeers,
                        [peerId]: { id: peerId, videoRef, stream: incomingStream },
                    };
                });
            }

        });

        call.on("error", (err) => {
            console.error("Call error:", err);
        });
    };

    return (
        <div>
            {camera ? (
                <video
                    ref={setWebcamRef}
                    autoPlay
                    playsInline
                    muted
                    className="border-top border-2 border-dark"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
            ) : (
                <>
                    <Modal onHide={() => setModalCam(false)} show={modalCam} size="lg" centered>
                        <div
                            className={`px-4 rounded-top text-center d-flex gap-2 `}
                            style={{ backgroundColor: "#272C36", marginTop: "-5px" }}
                        >
                            <div className="text-center pt-2 w-100">
                                <button className="btn p-2 text-danger btn-icon" onClick={toggleAudio}>
                                    {audio ? (
                                        <i className="fa-solid fa-microphone fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-microphone-slash fa-lg"></i>
                                    )}
                                </button>
                                <button className="btn p-2 text-info btn-icon" onClick={toggleVideo}>
                                    {video ? (
                                        <i className="fa-solid fa-video fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-video-slash fa-lg"></i>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div
                            className={`${hide ? "d-none" : "d-flex"
                                } flex-column text-white rounded-3 rounded-bottom`}
                            style={{ backgroundColor: "#1f232b", position: "relative" }}
                        >
                            <div className="d-flex" style={{ position: "relative", height: "273px" }}>
                                {video && modalCam ? (
                                    <video
                                        ref={setWebcamRef}
                                        autoPlay
                                        playsInline
                                        className="border-top border-2 border-dark"
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    />
                                ) : (
                                    !hide && <i className="fa-solid fa-video-slash fa-lg m-auto"></i>
                                )}
                                <div
                                    className="text-center"
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        width: "100%",
                                        zIndex: 2,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <button className="btn btn-primary" onClick={() => setModalCam(false)}>
                            Join
                        </button>
                    </Modal>
                    <div className="shadow round-top round-bottom">
                        <div
                            className={`px-4 text-center d-flex gap-2 round-top ${hide ? "round-bottom" : ""}`}
                            style={{ backgroundColor: "#272C36", marginTop: "-5px" }}
                        >
                            <div className="text-center pt-2 w-100">
                                <button className="btn p-2 text-success btn-icon" onClick={() => openChat()}>
                                    <i className="fa-solid fa-comments"></i>
                                </button>
                                <button className="btn p-2 text-danger btn-icon" onClick={toggleAudio}>
                                    {audio ? (
                                        <i className="fa-solid fa-microphone fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-microphone-slash fa-lg"></i>
                                    )}
                                </button>
                                <button className="btn p-2 text-info btn-icon" onClick={toggleVideo}>
                                    {video ? (
                                        <i className="fa-solid fa-video fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-video-slash fa-lg"></i>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div
                            className={`${hide ? "d-none" : "d-flex"} flex-column text-white round-bottom`}
                            style={{ backgroundColor: "#1f232b", position: "relative" }}
                        >
                            {/* Local Video */}
                            <div className="d-flex" style={{ position: "relative", height: "173px" }}>
                                {video && !modalCam ? (
                                    <>
                                        <video
                                            ref={setWebcamRef}
                                            autoPlay
                                            playsInline
                                            muted
                                            className="border-top border-2 border-dark"
                                            onPlay={handleVideoOnPlay}
                                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        />
                                    </>

                                ) : (
                                    !hide && <i className="fa-solid fa-video-slash fa-lg m-auto"></i>
                                )}
                                <div
                                    className="text-center"
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        width: "100%",
                                        zIndex: 2,
                                    }}
                                >
                                    <span className="bg-dark px-3 rounded-pill text-white opacity-75 w-auto">
                                        {user.displayName}
                                    </span>
                                </div>
                            </div>

                            {/* Remote Videos */}
                            {Object.values(remotePeers).map((peer) => (
                                <RemotePeerVideo key={peer.id} peer={peer} />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
