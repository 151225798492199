import React, { useState, useReducer, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";


import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config";
import { deletePostAction, getPostsAction } from "../../../../store/actions/PostActions";
import Swal from "sweetalert2";
import BasicModal from "./BasicModal";
import ScheduleCard from "../../Schedule/ScheduleCard";
export const StudentSchedule = () => {
  const [searchBut, setSearchBut] = useState(false);
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState([]);
  const img = config.apiUrl + 'uploads?image=practicums/'
  const childRef = useRef();
  const fetchData = async () => {
    try {
      const response = await dispatch(getPostsAction('/schedule/index', {
        params: {
          pageSize: 8,
          pageNumber: 1,
        },
      }));
      if (response) {
        setPageData(response)
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Practicum</h4>
          <div className="header-left">
            <div className="input-group search-area">
              <input type="text" className={`form-control ${searchBut ? "active" : ""}`} placeholder="Search here..." />
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                      fill="#01A3FF"
                    />
                  </svg>
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {pageData.map((item, index) => (
              <ScheduleCard data={item} key={index} action={childRef} role={0}/>
            ))}
          </div>
        </div>
        <div className="table-pagenation">
          <p className="ms-0">
            Showing <span>1-3</span>from <span>20</span>data
          </p>
          <nav>
            <ul className="pagination pagination-gutter pagination-primary no-bg">
              <li className="page-item page-indicator">
                <Link to={"#"} className="page-link">
                  <i className="fa-solid fa-angle-left"></i>
                </Link>
              </li>
              <li className="page-item active">
                <Link to={"#"} className="page-link">
                  1
                </Link>
              </li>
              <li className="page-item ">
                <Link className="page-link">2</Link>
              </li>
              <li className="page-item">
                <Link className="page-link">3</Link>
              </li>
              <li className="page-item page-indicator me-0">
                <Link to={"#"} className="page-link">
                  <i className="fa-solid fa-angle-right"></i>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <BasicModal ref={childRef} />
    </>
  );
};

export default StudentSchedule;
