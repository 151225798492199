import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPostFileAction } from "../../../store/actions/PostActions";
import { useDispatch } from "react-redux";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const [title, setTitle] = useState('');
  const [tag, setTag] = useState('');
  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('module_name', title);
    formData.append('tag', tag);
    const response = dispatch(createPostFileAction('/project/store', formData))
    if(response){
      setModalBox(false)
    }
  };
  return (
    <>
      <Modal onHide={setModalBox} size="" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Project</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalBox(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <label
                htmlFor="file-upload"
                style={{
                  cursor: "pointer",
                  display: "block",
                  textAlign: "center",
                  lineHeight: "250px",
                  border: "1px dashed #ccc",
                  borderRadius: "4px",
                }}
              >
                Upload Image (450x250)
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
            </div>
            <div className="form-group mb-3">
              <input
                className="form-control"
                placeholder="Title"
                defaultValue={""}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <input
                className="form-control"
                placeholder="Tag"
                defaultValue={""}
                onChange={(e) => setTag(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setModalBox(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;
