import React, { useEffect, useState } from "react";

import EventCalendar from "./EventCalendar";
import { getPostsAction } from "../../../../../store/actions/PostActions";
import { useDispatch } from "react-redux";


const DashboardStudent = () => {
   const dispatch = useDispatch();
   const [pageData,setPageData] = useState([]);
   const fetchData = async () => {
		try {
			const response = await dispatch(
				getPostsAction("/schedule/index", {
					params: {
						pageSize: 8,
						pageNumber: 1,
					},
				})
			);
			if (response) {
				setPageData(response);
			}
		} catch (error) {
			console.error("Error fetching posts:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);
   return (
      <div className="row">
         <div className="col-xl-12">
            <div className="row">
               <div className="col-xl-12">
                  <div className="page-titles style1">
                     <div className="d-flex align-items-center">
                        <h2 className="heading">Dashboard</h2>
                     </div>
                  </div>
               </div>
            </div>
            <EventCalendar data={pageData}/>
         </div>
      </div>

   );
};

export default DashboardStudent;
