import React, { useState } from 'react';
import { createPostFileAction, deletePostAction } from '../../../store/actions/PostActions';
import { useDispatch } from 'react-redux';
import config from '../../../config';
import { Editor } from '@tinymce/tinymce-react';
// import MathType from '@wiris/mathtype-ckeditor5/dist/index.js';
const apiUrl = config.apiUrl;
const url = config.url;


const CKEditorBlog = ({ data, config, setData, onSubmit, id }) => {
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const handleImageUpload = async (blobInfo, success, failure) => {
        const data = new FormData();
        data.append('image', blobInfo.blob(), blobInfo.filename());

        const response = await dispatch(createPostFileAction('/question/image', data));
        if (response) {
            if (response.data && response.data.filename) {
                return apiUrl + '/uploads?image=editor/' + response.data.filename;
            } else {
                throw new Error('Unable to upload image');
            }
        } else {
            throw new Error('Unable to upload image');
        }
    };
    const handleImageDelete = (editor) => {
        editor.editing.view.document.on('keydown', (event, data) => {
            if (data.keyCode === 8) {
                const selection = editor.model.document.selection;
                const range = selection.getFirstRange();
                const selectedElement = range.start.nodeBefore;
                if (selectedElement && selectedElement.is('element', 'image')) {
                    const imageUrl = selectedElement.getAttribute('src');
                    const imageName = imageUrl.split('/').pop();
                    const response = this.dispatch(deletePostAction('/question/delete-image/', imageName));
                    if (response) {
                        console.log('Image deleted successfully');
                    }
                    editor.model.change((writer) => {
                        writer.remove(selectedElement);
                    });
                }
            }
        });
    };
    return (
        <>
            <Editor
                apiKey='ic1tijoenzoxjnusd8wroqel5djextuge0mw28hw58vnqh3m'
                onEditorChange={(content, editor) => {
                    const text = content;
                    if (id) {
                        setData(text, id);
                    } else {
                        setData(text);
                    }
                    const currentImages = Array.from(editor.getBody().querySelectorAll('img')).map(img => img.src);

                    const deletedImages = images.filter(img => !currentImages.includes(img));
                    if (deletedImages.length > 0) {
                        handleImageDelete(deletedImages);
                    }
                    setImages(currentImages);
                    setData(content);
                }}
                value={data}
                init={{
                    height: 200,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'media'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help' + 'image | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_upload_handler: handleImageUpload,
                    // external_plugins: {
                    //     tiny_mce_wiris: 'https://unpkg.com/@wiris/mathtype-tinymce5@8.11.1/plugin.min.js',
                    // },
                }}

            />
            {/* <ClassicEditor
                config={{
                    licenseKey: "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3MzQ4MjU1OTksImp0aSI6IjkwOTMzYjU2LWJjMWEtNDkyNS05NTNlLTkwZDQ0MTRmMmIwOSIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiLCJzaCJdLCJ3aGl0ZUxhYmVsIjp0cnVlLCJsaWNlbnNlVHlwZSI6InRyaWFsIiwiZmVhdHVyZXMiOlsiKiJdLCJ2YyI6IjcxNzk5MTVmIn0.f9724QjuGMEutDiH3U5LFw7hlXPd1szpINVirdKgOoMsrvezZegTVkqwqg20cA8VmegCl34ysRsq5jLv5SRDEw",
                    plugins: [MathType],
                    toolbar: ['MathType', 'ChemType'],
                    image: {
                        toolbar: ['imageTextAlternative', '|', 'imageStyle:full', 'imageStyle:side'],
                        styles: ['full', 'side'],
                    },

                }}
                data={data}
                onReady={(editor) => {
                    imagePlugin(editor);
                    handleBackspaceDelete(editor);
                }}
                onChange={(event, editor) => {
                    const text = editor.getData();
                    if (id) {
                        setData(text, id);
                    } else {
                        setData(text);
                    }
                }}
                onBlur={(event, editor) => { }}
                onFocus={(event, editor) => { }}
            /> */}
        </>
    );
};

export default CKEditorBlog;
