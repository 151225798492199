
let SHOW_MESH = true
let SHOW_TAG_NUMBERS = true
let DRAW_DIRECTION = true

export const DrawMesh = (prediction) => {
  if (!prediction) return;
  const keyPoints = prediction.keypoints;
  if (!keyPoints || keyPoints.length === 0) return;

  return calculateDirection(keyPoints);
};

function calculateDirection(keyPoints) {
  let noseTip, leftNose, rightNose, leftEye, rightEye;
  try {
    noseTip = { ...keyPoints[1], name: "nose tip" };
    leftNose = { ...keyPoints[279], name: "left nose" };
    rightNose = { ...keyPoints[49], name: "right nose" };
    leftEye = { ...keyPoints[33], name: "left eye" }
    rightEye = { ...keyPoints[133], name: "right eye" }
  } catch (error) {
    console.log("error creating directional points", keyPoints, error);
  }

  // MIDESCTION OF NOSE IS BACK OF NOSE PERPENDICULAR
  const midpoint = {
    x: (leftNose.x + rightNose.x) / 2,
    y: (leftNose.y + rightNose.y) / 2,
    z: (leftNose.z + rightNose.z) / 2,
  };
  const perpendicularUp = { x: midpoint.x, y: midpoint.y - 50, z: midpoint.z };

  // CALC ANGLES
  const yaw = getAngleBetweenLines(midpoint, noseTip, perpendicularUp);
  const turn = getAngleBetweenLines(midpoint, rightNose, noseTip);
  const lEye = {
    x: leftEye.x.toFixed(2),  // Use the `x` and `y` from the leftEye object
    y: leftEye.y.toFixed(2),
  };

  const rEye = {
    x: rightEye.x.toFixed(2),  // Use the `x` and `y` from the rightEye object
    y: rightEye.y.toFixed(2),
  };

  // CALC DISTANCE BETWEEN NOSE TIP AND MIDPOINT, AND LEFT AND RIGHT NOSE POINTS
  const zDistance = getDistanceBetweenPoints(noseTip, midpoint)
  const xDistance = getDistanceBetweenPoints(leftNose, rightNose)

  return { yaw, turn, zDistance, xDistance, lEye, rEye }
}

function getDistanceBetweenPoints(point1, point2) {
  const xDistance = point1.x - point2.x;
  const yDistance = point1.y - point2.y;
  return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
}

function getAngleBetweenLines(midpoint, point1, point2) {
  const vector1 = { x: point1.x - midpoint.x, y: point1.y - midpoint.y };
  const vector2 = { x: point2.x - midpoint.x, y: point2.y - midpoint.y };

  // Calculate the dot product of the two vectors
  const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;

  // Calculate the magnitudes of the vectors
  const magnitude1 = Math.sqrt(vector1.x * vector1.x + vector1.y * vector1.y);
  const magnitude2 = Math.sqrt(vector2.x * vector2.x + vector2.y * vector2.y);

  // Calculate the cosine of the angle between the two vectors
  const cosineTheta = dotProduct / (magnitude1 * magnitude2);

  // Use the arccosine function to get the angle in radians
  const angleInRadians = Math.acos(cosineTheta);

  // Convert the angle to degrees
  const angleInDegrees = (angleInRadians * 180) / Math.PI;

  return angleInDegrees;
}
