import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createPostFileAction, getPostsAction } from "../../../store/actions/PostActions";
import CKEditorBlog from "../Utils/CkEditorBlog";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));
  const [des, setDes] = useState("<p></p>");
  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("icon", selectedFile);
    formData.append("name", title);
    formData.append("des", des);
    let error = false;
    // setModalBox(false)
    const response = dispatch(createPostFileAction("/fancybox/store", formData));
    console.log(response);
    setModalBox(false);
    // const errorObj = { ...errorsObj };
    // if (email === '') {
    // 	errorObj.email = 'Email is Required';
    // 	error = true;
    // }
    // if (password === '') {
    // 	errorObj.password = 'Password is Required';
    // 	error = true;
    // }
    // setErrors(errorObj);
    // if (error) {
    // 	return;
    // }
    // dispatch(loadingToggleAction(true));
  };
  return (
    <>
      <Modal onHide={setModalBox} size="xl" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Fancybox</h5>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <div>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      textAlign: "center",
                      lineHeight: "220px",
                      border: "1px dashed #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    Upload Image (450x250)
                  </label>
                  <input id="file-upload" type="file" style={{ display: "none" }} onChange={handleFileChange} />
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-3">
                  <label htmlFor="basic-url" className="form-label d-block">
                    Descriptions
                  </label>
                  <CKEditorBlog data={des} setData={setDes} />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setModalBox(false)}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;
