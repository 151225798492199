import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createPostFileAction } from "../../../store/actions/PostActions";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));

  const [nama, setNama] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('module_name', nama);
    formData.append('image', selectedFile);

    const response = dispatch(createPostFileAction('/test/store', formData))
    setSelectedFile(null);
    console.log(response)
    setModalBox(false)
  };

  return (
    <>
      <Modal onHide={setModalBox} size="" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Test</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalBox(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3 d-block">
              <label htmlFor="basic-url" className="form-label d-block">
                Nama
              </label>
              <input
                type="text"
                className="form-control w-100"
                placeholder="Masukkan Nama"
                onChange={(e) => { setNama(e.target.value) }}
              />
            </div>
            <div>
              <label
                htmlFor="file-upload"
                style={{
                  cursor: "pointer",
                  display: "block",
                  textAlign: "center",
                  lineHeight: "250px",
                  border: "1px dashed #ccc",
                  borderRadius: "4px",
                }}
              >
                Upload Image (450x250)
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setModalBox(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;
