import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { DrawMesh } from './DrawMesh';

const DRAW_DELAY = 300;
let lastFaceDetectedTime = Date.now();
let noFaceDetectedTime = 0;
let store = 0;
export const runDetector = async (video, cb) => {
  const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
  const detectorConfig = {
    runtime: 'mediapipe', 
    solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
  };

  const detector = await faceLandmarksDetection.createDetector(model, detectorConfig);

  const detect = async (net) => {
    const estimationConfig = { flipHorizontal: true };
    try {
      const faces = await net.estimateFaces(video, estimationConfig);

      setTimeout(() => {
        requestAnimationFrame(() => {
          if (faces.length > 0) {
            lastFaceDetectedTime = Date.now();
            if(store){
              lastFaceDetectedTime = store
            }
            const angle = DrawMesh(faces[0]);
            cb(angle);
          } else {
            // const currentTime = Date.now();
            // store = lastFaceDetectedTime;
            // noFaceDetectedTime = Math.floor((currentTime - store) / 1000);
            // console.log(`No face detected for ${noFaceDetectedTime} seconds`);
          }

          detect(detector);
        });
      }, DRAW_DELAY);
    } catch (error) {
      console.error('Error in detection loop:', error);
    }
  };

  detect(detector);
};
