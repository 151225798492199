import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updatePostAction } from "../../../store/actions/PostActions";
import config from "../../../config";
const img = config.apiUrl + "uploads?image=feature/";
const EditModal = forwardRef(({ editData, onEditSuccess }, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));

  const [formData, setFormData] = useState({
    name: "",
  });

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [previewUrl, setPreviewUrl] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (editData) {
      setImage(editData.icon); // Set the title from editData
      setTitle(editData.name); // Set the title from editData
      setPreviewUrl(img + editData.icon);
    }
  }, [editData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // File dipilih, buat preview URL
      const previewUrl = URL.createObjectURL(file);
      setSelectedFile(file);
      setPreviewUrl(previewUrl);
    } else {
      // Tidak ada file yang dipilih (cancel), kembali ke gambar default
      setSelectedFile(null);
      setPreviewUrl(img + image); // Set ke gambar default lagi
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();

    if (selectedFile) {
      updatedFormData.append("icon", selectedFile);
      updatedFormData.append("oldIcon", editData.image);
    }
    updatedFormData.append("name", title);

    if (editData && editData.id) {
      // Dispatch edit action for the specific post
      dispatch(updatePostAction(`feature/update`, updatedFormData, editData.id))
        .then(() => {
          onEditSuccess(); // Panggil callback untuk memberitahu Hero bahwa update berhasil
          setSelectedFile(null);
        })
        .catch((error) => {
          console.error("Error updating post:", error);
        });
    }

    setModalBox(false);
  };
  return (
    <>
      <Modal onHide={setModalBox} size="sm" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Documentation</h5>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <div>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      lineHeight: "220px",
                      border: "1px dashed #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    <img src={previewUrl} style={{ maxWidth: "245px", objectFit: "cover" }} alt={title} />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setModalBox(false)}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default EditModal;
